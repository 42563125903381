/* globals window, document */
/**
 * Styles
 */
require('../pages/cross-site/styles.scss');

/**
 * Polyfills
 */
require('./polyfills');

/**
 * Module dependencies
 */
const React = require('react');
const { AndesProvider } = require('@andes/context');
const I18n = require('nordic/i18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const { hydrate } = require('nordic/hydrate');
const CrossSiteView = require('../pages/cross-site/view');

/**
 * Get server state
 */
const preloadedState = window.__PRELOADED_STATE__;
const { locale, device, translations } = preloadedState;

const i18n = new I18n({
  translations,
});

/**
 * Mount crossSiteView on client
 */
hydrate(
  <AndesProvider locale={locale} device={device}>
    <I18nProvider i18n={i18n}>
      <CrossSiteView {...preloadedState} />
    </I18nProvider>
  </AndesProvider>,
  document.getElementById('root-app'),
);
