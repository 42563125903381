const React = require('react');
const PropTypes = require('prop-types');
const serialize = require('serialize-javascript');
const Style = require('nordic/style');
const Script = require('nordic/script');
const { CrossSite } = require('op-frontend-commons');
const HeadView = require('../../components/HeadView');

const CrossSiteView = (props) => {
  const { texts } = props;
  const imgTag = 'https://http2.mlstatic.com/storage/developers-site-cms-admin/DevSite/366633181898-logo_developers.png';

  return (
    <div>
      <HeadView title={texts.title} description={texts.description} imgTag={imgTag} />
      <Style href="crosssite.css" />
      <CrossSite
        applicationName="Developers"
        countries={[
          {
            href: 'https://developers.mercadolibre.com.ar',
            name: 'Argentina',
          },
          {
            href: 'https://developers.mercadolibre.com.bo',
            name: 'Bolivia',
          },
          {
            href: 'https://developers.mercadolivre.com.br',
            name: 'Brasil',
          },
          {
            href: 'https://developers.mercadolibre.cl',
            name: 'Chile',
          },
          {
            href: 'https://developers.mercadolibre.com.co',
            name: 'Colombia',
          },
          {
            href: 'https://developers.mercadolibre.co.cr',
            name: 'Costa Rica',
          },
          {
            href: 'https://developers.mercadolibre.com.do',
            name: 'Dominicana',
          },
          {
            href: 'https://developers.mercadolibre.com.ec',
            name: 'Ecuador',
          },
          {
            href: 'https://developers.mercadolibre.com.gt',
            name: 'Guatemala',
          },
          {
            href: 'https://developers.mercadolibre.com.hn',
            name: 'Honduras',
          },
          {
            href: 'https://developers.mercadolibre.com.mx',
            name: 'México',
          },
          {
            href: 'https://developers.mercadolibre.com.ni',
            name: 'Nicaragua',
          },
          {
            href: 'https://developers.mercadolibre.com.pa',
            name: 'Panamá',
          },
          {
            href: 'https://developers.mercadolibre.com.py',
            name: 'Paraguay',
          },
          {
            href: 'https://developers.mercadolibre.com.pe',
            name: 'Perú',
          },
          {
            href: 'https://developers.mercadolibre.com.sv',
            name: 'El Salvador',
          },
          {
            href: 'https://developers.mercadolibre.com.uy',
            name: 'Uruguay',
          },
          {
            href: 'https://developers.mercadolibre.com.ve',
            name: 'Venezuela',
          },
        ]}
      />
      <Script>{`window.__PRELOADED_STATE__ = ${serialize({ texts }, { isJSON: true })};`}</Script>
      <Script src="vendor.js" />
      <Script src="crosssite.js" />
    </div>
  );
};

CrossSiteView.defaultProps = {
  texts: {},
};

CrossSiteView.propTypes = {
  texts: PropTypes.objectOf(PropTypes.string).isRequired,
};

module.exports = CrossSiteView;
